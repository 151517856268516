// collection of objects that define endpoints to be used in SupportTools.vue

const supportRequests = {
  mergeProjects: {
    title: 'Merge Projects',
    endpoint: 'v1/admin/support/employers/:employer_id/merge_projects',
    method: 'POST',
    inputs: [
      {
        label: 'Employer ID',
        description: '',
        placeholder: '1999',
        parameterId: 'employer_id',
        type: 'text',
      },
      {
        label: 'Project IDs to Merge',
        description: 'The project(s) you want to be merged separated with commas',
        placeholder: '123456, 123457',
        parameterId: 'project_ids_to_merge',
        type: 'text',
        array: true,
      },
      {
        label: 'Project ID to Keep',
        description: 'The project you want to keep',
        placeholder: '123458',
        parameterId: 'project_id_to_keep',
        type: 'text',
      },
      {
        label: 'User Email',
        description: '',
        placeholder: 'johnny.appleseed@guardian.com',
        parameterId: 'user_email',
        type: 'text',
      },
    ],
  },
  getProjectInfo: {
    title: 'Get Project Info',
    endpoint: 'v1/admin/projects/:project_id',
    method: 'GET',
    inputs: [
      {
        label: 'Project ID',
        description: 'Enter the ID of the project',
        placeholder: '123456',
        parameterId: 'project_id',
        type: 'text',
      },
    ],
  },
  updateBroker: {
    title: 'Update Broker',
    endpoint: 'v1/admin/brokers/:id',
    method: 'PATCH',
    inputs: [
      {
        label: 'Broker ID',
        placeholder: '33',
        parameterId: 'id',
        type: 'text',
      },
      {
        label: 'Name',
        placeholder: 'Sterling',
        parameterId: 'name',
        type: 'text',
      },
      {
        label: 'Disclosure',
        placeholder: 'This is a disclosure',
        parameterId: 'disclosure',
        type: 'text',
      },
      {
        label: 'Privacy Statement',
        placeholder: 'This is a privacy statement',
        parameterId: 'privacy_statement',
        type: 'text',
      },
    ],
  },
};

export default supportRequests;
