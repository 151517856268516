<template>
  <ElDialog
    :visible.sync="visible"
    :title="parameters.title"
    @close="handleClose"
  >
    <ElForm v-if="parameters.inputs" :model="formData">
      <ElFormItem
        v-for="(input, index) in parameters.inputs"
        :key="input.parameterId + index"
      >
        <div class="request-info">
          <div
            class="input-label"
            :class="{ 'with-desc': input.description }"
          >
            {{ input.label }}
          </div>
          <div v-if="input.description" class="input-description">
            {{ input.description }}
          </div>
        </div>
        <ElInput
          v-model="formData[input.parameterId]"
          :type="input.type"
          :placeholder="input.placeholder"
        />
      </ElFormItem>
    </ElForm>
    <div v-if="response" class="response-block">
      <pre class="response-code">
        <code>{{ response }}</code>
      </pre>
    </div>
    <div class="btn-group">
      <AppButton
        size="text"
        type="primary"
        :text="response ? 'Close' : 'Cancel'"
        @click="handleClose"
      />
      <AppButton
        type="primary"
        text="Submit"
        @click="handleSubmit"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { makeRequest } from '@/services/supportService.js';

  /**
   * Handles the display and submission of a request form within a modal dialog.
   *
   * @vuedoc
   * @exports RequestModal
   * @category Components
   */
  export default {
    props: {
      /**
       * Controls the visibility of the modal.
       *
       * @type {boolean}
       */
      value: {
        type: Boolean,
        required: true,
      },
      /**
       * Values that represent endpoint information for each request.
       *
       * @type {object}
       * @property {string} title - title of request
       * @property {string} endpoint - endpoint string
       * @property {string} method - HTTP method type
       * @property {object} inputs - endpoint attributes
       */
      parameters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formData: {},
        response: null,
        responseCode: null,
      };
    },
    computed: {
      visible: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      /**
       * Close the request modal & clear it's fields.
       *
       */
      handleClose() {
        this.formData = {};
        this.response = null;
        this.$emit('input', false);
      },
      /**
       * Extract input data and convert to different data types if required.
       *
       * @returns {object}
       */
      handleFormatInput() {
        const params = {};

        this.parameters.inputs.forEach((input) => {
          if (input.parameterId) {
            if (input.array && this.formData[input.parameterId]) {
              const formattedValue = this.formData[input.parameterId].split(',').map((item) => item.trim());

              params[input.parameterId] = formattedValue;
            } else {
              params[input.parameterId] = this.formData[input.parameterId];
            }
          }
        });

        return params;
      },
      /**
       * Make the request with params & display response data.
       *
       */
      async handleSubmit() {
        const { endpoint, method } = this.parameters;
        const params = this.handleFormatInput();

        try {
          const response = await makeRequest(endpoint, params, method);

          if (response.status === 200) {
            this.response = JSON.stringify(response.data, null, 2);
            this.displayToast({
              message: 'Request success',
              type: 'success',
            });
          } else {
            this.response = response.data.message || 'An error occurred';
            this.displayToast({
              message: 'Request failed',
              type: 'error',
            });
          }
        } catch (error) {
          this.displayToast({
            message: 'Request failed',
            type: 'error',
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
$margin-bottom: 10px;

.request-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.copy-button {
  position: absolute;
  top: 15px;
  right: 20px;
}

.input-label {
  font-weight: bold;
  margin-bottom: $margin-bottom;

  &.with-desc {
    margin-bottom: 0;
  }
}

::v-deep .el-dialog {
  padding: 20px;
  width: 50%;
}

.input-description {
  margin-bottom: 12px;
  margin-top: 6px;
  font-size: 12px;
  color: var(--tf-gray);
}

.response-block {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--tf-gray-light-medium);
  max-height: 250px;
  overflow: auto;
}

.response-code {
  margin: 0;
  white-space: pre-wrap;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
}
</style>
